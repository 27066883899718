import { SidebarItemsType } from "../../types/sidebar";

import { Calendar, ShoppingCart } from "react-feather";

const customerSection = [
  {
    href: "/orders",
    icon: ShoppingCart,
    title: "Orders",
  },
] as SidebarItemsType[];

const operationSection = [
  {
    href: "/movements",
    icon: Calendar,
    title: "Daily Movements",
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "customer",
    pages: customerSection,
  },
  {
    title: "operation",
    pages: operationSection,
  },
];

export default navItems;
