import { Alert, Container, Divider, Paper, Stack } from "@mui/material";
import Loader from "../../../../components/Loader";
import { ActionsTargets, OrdersActions } from "../../../../types/auth";
import { OrderComment } from "../../../../types/orders";
import { isAuthorized } from "../../../../utils/auth";
import Comment from "./Comment";
import CommentsHeader from "./Header";
import CommentInput from "./Input";

interface OrderCommentsProps {
  commentsData: OrderComment[] | undefined;
  orderId: string;
  isError: boolean;
  isLoading: boolean;
}

const OrderComments = ({ commentsData, orderId, isError, isLoading }: OrderCommentsProps) => {
  const commentsDataReversed = commentsData?.slice().reverse();

  return (
    <Paper>
      <CommentsHeader commentsLength={commentsData?.length} />
      <Divider />
      <Container sx={{ overflow: "auto" }} maxWidth={false}>
        {isLoading ? (
          <Loader />
        ) : isError ? (
          <Alert severity="error" sx={{ m: 2 }}>
            An error occurred while retrieving the comments of the order
          </Alert>
        ) : (
          <Stack py={4} px={2} spacing={3}>
            <Stack>
              {commentsDataReversed?.map((comment) => (
                <Comment key={comment.id} commentData={comment} />
              ))}
            </Stack>
            {isAuthorized(OrdersActions.PostComment, ActionsTargets.Orders) && (
              <>
                {!!commentsData?.length && <Divider />}
                <CommentInput orderId={orderId} />
              </>
            )}
          </Stack>
        )}
      </Container>
    </Paper>
  );
};

export default OrderComments;
