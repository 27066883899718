import BTServer from "./axiosInterceptors/BTServer";
import { Site, Slot, SlotType } from "../types/sites";

export const getSites = (): Promise<Site[]> =>
  BTServer.get<Site[]>("/sites").then((res) => res.data);

export const getSiteDailySlots = (
  siteId: number | undefined,
  day: string | undefined,
  type: SlotType
): Promise<Slot[]> => {
  return typeof siteId === "undefined" || typeof day === "undefined"
    ? Promise.reject(new Error("Invalid parameters"))
    : BTServer.get<Slot[]>(`/admin/sites/${siteId}/slots/${type}/${day}`).then<Slot[]>(
        (res) => res.data
      );
};
