import OrderList from "./pages/orders/ordersList";
import { Navigate } from "react-router-dom";
import Order from "./pages/orders/orderDetails";
import MainLayout from "./layouts/MainLayout";
import DailyMovementsList from "./pages/dailyMovements";
import Page404 from "./pages/auth/Page404";
import OrderCreation from "./pages/orders/ordersCreation";

const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "", element: <Navigate to="/orders" /> },
      { path: "orders", element: <OrderList /> },
      { path: "orders/create", element: <OrderCreation /> },
      { path: "orders/:id", element: <Order /> },
      { path: "movements", element: <DailyMovementsList /> },
    ],
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export default routes;
