import * as React from "react";
import styled, { withTheme } from "styled-components/macro";
import { Grid, AppBar as MuiAppBar, IconButton as MuiIconButton, Toolbar } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import SiteSelector from "./SiteSelector";
import { useLocation } from "react-router-dom";
import SignOut from "./SignOut";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

type NavbarProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  const { pathname } = useLocation();

  const displaySiteSelectorUrls: string[] = ["/movements"];

  const displaySiteSelector = displaySiteSelectorUrls.includes(pathname);

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item sx={{ display: { md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            {displaySiteSelector && (
              <Grid item xs={6} sm={5} md={3} xl={2}>
                <SiteSelector />
              </Grid>
            )}
            <Grid item sx={{ marginLeft: { md: "auto" } }}>
              <SignOut />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
