import { KeycloakTokenParsed } from "keycloak-js";
import keycloak from "../keycloak";
import { Roles, OrdersActions, ActionsTargets, AuthorizationsType } from "../types/auth";
import { Site } from "../types/sites";
import * as auth from "./auth";

export interface KeycloakTokenParsedExtended extends KeycloakTokenParsed {
  bt_sites: number[];
}

export const getAuthorizedSitesIds = (): number[] => {
  const token = keycloak.tokenParsed as KeycloakTokenParsedExtended;
  return token?.bt_sites ? token.bt_sites : [];
};

export const getAuthorizedSites = (sites: Site[]): Site[] => {
  const authorizedSites = sites.filter((site) => getAuthorizedSitesIds().includes(site.id));
  return hasSiteConstraint() ? authorizedSites : sites;
};

export const getRole = () =>
  keycloak.realmAccess?.roles.find((role) => {
    const rolesList: string[] = Object.values(Roles);
    return rolesList.includes(role);
  });

const Authorizations: AuthorizationsType = {
  [ActionsTargets.Orders]: {
    [OrdersActions.Read]: {
      [Roles.CosAgent]: true,
      [Roles.SiteManager]: true,
      [Roles.Driver]: true,
    },
    [OrdersActions.Edit]: {
      [Roles.CosAgent]: true,
      [Roles.SiteManager]: true,
      [Roles.Driver]: true,
    },
    [OrdersActions.EditRestrictedFields]: {
      [Roles.CosAgent]: true,
      [Roles.SiteManager]: false,
      [Roles.Driver]: false,
    },
    [OrdersActions.SendEmail]: {
      [Roles.CosAgent]: true,
      [Roles.SiteManager]: false,
      [Roles.Driver]: false,
    },
    [OrdersActions.PostComment]: {
      [Roles.CosAgent]: true,
      [Roles.SiteManager]: true,
      [Roles.Driver]: false,
    },
    [OrdersActions.Create]: {
      [Roles.CosAgent]: true,
      [Roles.SiteManager]: false,
      [Roles.Driver]: false,
    },
    [OrdersActions.Cancel]: {
      [Roles.CosAgent]: true,
      [Roles.SiteManager]: false,
      [Roles.Driver]: false,
    },
  },
};

export function isAuthorized(
  action: OrdersActions,
  target: ActionsTargets,
  siteId?: number
): boolean {
  const role = auth.getRole();

  switch (role) {
    case Roles.Admin:
      return true;
    case Roles.CosAgent:
      return Authorizations[target][action][role];
    case Roles.SiteManager:
    case Roles.Driver:
      if (siteId) {
        const isAnAuthorizedSite = auth.getAuthorizedSitesIds().some((site) => siteId === site);
        return isAnAuthorizedSite ? Authorizations[target][action][role] : false;
      }
      return Authorizations[target][action][role];
    default:
      return false;
  }
}

export function hasSiteConstraint(): boolean {
  const role = getRole();
  return !(role === Roles.Admin || role === Roles.CosAgent);
}
