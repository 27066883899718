import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Container, Typography } from "@mui/material";
import SignOut from "../../components/SignOut";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(2)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(2)};
  }
`;

function Page403() {
  return (
    <Wrapper>
      <Helmet title="Missing Role" />
      <Container sx={{ display: "flex", justifyContent: "flex-end" }}>
        <SignOut />
      </Container>
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        403
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Unauthorized
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        Please contact an administrator, to get permission to access the back office
      </Typography>
    </Wrapper>
  );
}

export default Page403;
