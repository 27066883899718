export enum Roles {
  Admin = "admin",
  CosAgent = "cos-agent",
  SiteManager = "site-manager",
  Driver = "driver",
}

export enum ActionsTargets {
  Orders = "orders",
}

export enum OrdersActions {
  Read = "read",
  Edit = "edit",
  EditRestrictedFields = "editRestrictedFields",
  SendEmail = "sendEmail",
  PostComment = "postComment",
  Create = "create",
  Cancel = "cancel",
}

export type Actions = OrdersActions;

export interface RolesAuthorizations {
  [Roles.CosAgent]: boolean;
  [Roles.SiteManager]: boolean;
  [Roles.Driver]: boolean;
}

export interface AuthorizationsType {
  [ActionsTargets.Orders]: {
    [OrdersActions.Read]: RolesAuthorizations;
    [OrdersActions.Edit]: RolesAuthorizations;
    [OrdersActions.EditRestrictedFields]: RolesAuthorizations;
    [OrdersActions.SendEmail]: RolesAuthorizations;
    [OrdersActions.PostComment]: RolesAuthorizations;
    [OrdersActions.Create]: RolesAuthorizations;
    [OrdersActions.Cancel]: RolesAuthorizations;
  };
}
