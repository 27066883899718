import { useState } from "react";
import { Button, Grid, Paper } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useMutation, useQueryClient } from "react-query";
import { LoadingButton } from "@mui/lab";
import { Replay } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import {
  Order,
  OrderFormikValues,
  OrderUpdateValues,
  UpdateOrderFormikValues,
} from "../../../types/orders";
import { Slot, SlotType } from "../../../types/sites";
import {
  convertMomentDateToZonedDateTime,
  convertZonedDateTimeToMomentDate,
} from "../../../utils/date";
import { updateOrder } from "../../../api/orders";
import BookingForm from "../../../components/form/BookingForm";
import CustomerForm from "../../../components/form/CustomerForm";
import { isAuthorized } from "../../../utils/auth";
import { ActionsTargets, OrdersActions } from "../../../types/auth";
import PlatformForm from "../../../components/form/PlatformForm";
import OperationForm from "../../../components/form/OperationForm";

const EnhancedOrderForm = ({
  orderData,
  isEditable,
}: {
  orderData: Order;
  isEditable: boolean;
}) => {
  const { t } = useTranslation();

  const dateFormat = "YYYY-MM-DD";
  const [siteStartSlots, setSiteStartSlots] = useState<Array<Slot>>([]);
  const [siteEndSlots, setSiteEndSlots] = useState<Array<Slot>>([]);

  const queryClient = useQueryClient();

  const initialStartDate = convertMomentDateToZonedDateTime(orderData.startDate);

  const initialEndDate = convertMomentDateToZonedDateTime(orderData.endDate);

  const getInitialValues = (order: Order): UpdateOrderFormikValues => ({
    [OrderFormikValues.siteId]: order.siteId,
    [OrderFormikValues.departureMeetingPointId]: order.departureMeetingPointId,
    [OrderFormikValues.arrivalMeetingPointId]: order.arrivalMeetingPointId,
    [OrderFormikValues.passengers]: order.passengers,
    [OrderFormikValues.arrivalFlightNumber]: order.arrivalFlightNumber,
    [OrderFormikValues.startDate]: initialStartDate,
    [OrderFormikValues.endDate]: initialEndDate,
    [OrderFormikValues.selectedStartDay]: order.startDate.format(dateFormat),
    [OrderFormikValues.selectedEndDay]: order.endDate.format(dateFormat),
    [OrderFormikValues.firstName]: order.firstName,
    [OrderFormikValues.lastName]: order.lastName,
    [OrderFormikValues.email]: order.email,
    [OrderFormikValues.phone]: order.phone,
    [OrderFormikValues.zipCode]: order.zipCode,
    [OrderFormikValues.address]: order.address,
    [OrderFormikValues.city]: order.city,
    [OrderFormikValues.vehicleModel]: order.vehicleModel,
    [OrderFormikValues.vehicleColor]: order.vehicleColor,
    [OrderFormikValues.platformId]: order.platformId,
    [OrderFormikValues.isPlatform]: !!order.platformId,
    [OrderFormikValues.externalOrderNumber]: order.externalOrderNumber,
    [OrderFormikValues.luggage]: order.luggage,
    [OrderFormikValues.parkName]: order.parkName,
    [OrderFormikValues.parkLocation]: order.parkLocation,
  });

  const updateToastId = `update_${orderData.id}`;

  const { mutate } = useMutation(
    "updateOrder",
    ({
      changedValues,
    }: {
      changedValues: OrderUpdateValues;
      resetForm: FormikHelpers<UpdateOrderFormikValues>["resetForm"];
    }) => updateOrder(orderData.id, changedValues),
    {
      onMutate: () => {
        toast.loading(`${orderData.number} update in progress`, {
          id: updateToastId,
        });
      },
      onSuccess: (response, { changedValues, resetForm }) => {
        toast.success(`${orderData.number} successfully updated`, {
          id: updateToastId,
        });
        if (response) {
          resetForm({ values: getInitialValues(response) });
          queryClient.setQueryData(["order", response.id], response);
          queryClient.invalidateQueries("orders");
          queryClient.invalidateQueries(["history", orderData.id]);
          if (changedValues.startDate || changedValues.passengers) {
            queryClient.invalidateQueries([
              "startSlots",
              {
                date: response.startDate.format(dateFormat),
                siteId: orderData.siteId,
              },
            ]);
            queryClient.invalidateQueries([
              "startSlots",
              {
                date: orderData.startDate.format(dateFormat),
                siteId: orderData.siteId,
              },
            ]);
          }
          if (changedValues.endDate || changedValues.passengers) {
            queryClient.invalidateQueries([
              "endSlots",
              {
                date: response.endDate.format(dateFormat),
                siteId: orderData.siteId,
              },
            ]);
            queryClient.invalidateQueries([
              "endSlots",
              {
                date: orderData.endDate.format(dateFormat),
                siteId: orderData.siteId,
              },
            ]);
          }
        }
      },
      onError: () => {
        toast.error(`Something went wrong during ${orderData.number} update`, {
          id: updateToastId,
        });
      },
    }
  );

  const validatePassengersAvailability = (
    selectedSlotStart: string | undefined,
    selectedPassengers: number,
    type: SlotType
  ) => {
    const slotsData = type === SlotType.Departure ? siteStartSlots : siteEndSlots;
    const currentSlots =
      type === SlotType.Departure
        ? convertMomentDateToZonedDateTime(orderData.startDate)
        : convertMomentDateToZonedDateTime(orderData.endDate);
    const availablePassengers = slotsData?.find(
      (slot) => slot.start === selectedSlotStart
    )?.availablePassengers;
    if (selectedSlotStart && availablePassengers) {
      if (moment().isAfter(convertZonedDateTimeToMomentDate(selectedSlotStart))) return false;
      else if (currentSlots === selectedSlotStart) {
        return availablePassengers >= selectedPassengers - orderData.passengers;
      } else {
        return availablePassengers >= selectedPassengers;
      }
    } else return true;
  };

  const isRestrictedFieldsEditable =
    isEditable && isAuthorized(OrdersActions.EditRestrictedFields, ActionsTargets.Orders);

  const initialValues = getInitialValues(orderData);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        [OrderFormikValues.isPlatform]: Yup.boolean(),
        [OrderFormikValues.siteId]: Yup.number().required(t("form.fieldError.fieldRequired")),
        [OrderFormikValues.departureMeetingPointId]: Yup.number().required(
          t("form.fieldError.fieldRequired")
        ),
        [OrderFormikValues.arrivalMeetingPointId]: Yup.number().required(
          t("form.fieldError.fieldRequired")
        ),
        [OrderFormikValues.startDate]: Yup.string()
          .required(t("form.fieldError.fieldRequired"))
          .test(
            "startDatePassengersAvailability",
            t("form.fieldError.passengersAvailabilityExceeded"),
            function (value) {
              return validatePassengersAvailability(
                value,
                this.parent.passengers,
                SlotType.Departure
              );
            }
          ),
        [OrderFormikValues.endDate]: Yup.string()
          .required(t("form.fieldError.fieldRequired"))
          .test(
            "endDatePassengersAvailability",
            t("form.fieldError.passengersAvailabilityExceeded"),
            function (value) {
              return validatePassengersAvailability(
                value,
                this.parent.passengers,
                SlotType.Arrival
              );
            }
          ),
        [OrderFormikValues.selectedStartDay]: Yup.string()
          .required(t("form.fieldError.fieldRequired"))
          .test(
            "selectedStartDayIsBeforeSelectedEndDay",
            t("form.fieldError.inAfterOut"),
            function (value) {
              return moment(value).isSameOrBefore(moment(this.parent.selectedEndDay));
            }
          ),
        [OrderFormikValues.selectedEndDay]: Yup.string()
          .required(t("form.fieldError.fieldRequired"))
          .test(
            "selectedStartDayIsAfterSelectedEndDay",
            t("form.fieldError.outBeforeIn"),
            function (value) {
              return moment(value).isSameOrAfter(moment(this.parent.selectedStartDay));
            }
          ),
        [OrderFormikValues.passengers]: Yup.number()
          .required(t("form.fieldError.fieldRequired"))
          .test(
            "passengersSlotsAvailability",
            t("form.fieldError.passengersAvailabilityExceeded"),
            function (value) {
              return (
                !!value &&
                (validatePassengersAvailability(this.parent.startDate, value, SlotType.Departure) ||
                  validatePassengersAvailability(this.parent.endDate, value, SlotType.Arrival))
              );
            }
          ),
        [OrderFormikValues.arrivalFlightNumber]: Yup.mixed().test(
          "flightNumberNullAuthorized",
          t("form.fieldError.fieldRequired"),
          (value) => !(value === null && orderData.arrivalFlightNumber)
        ),
        [OrderFormikValues.firstName]: Yup.string().when("isPlatform", {
          is: true,
          then: Yup.string().nullable(true).optional(),
          otherwise: Yup.string().required(t("form.fieldError.fieldRequired")),
        }),
        [OrderFormikValues.lastName]: Yup.string().when("isPlatform", {
          is: true,
          then: Yup.string().nullable(true).optional(),
          otherwise: Yup.string().required(t("form.fieldError.fieldRequired")),
        }),
        [OrderFormikValues.email]: Yup.string().when("isPlatform", {
          is: true,
          then: Yup.string().nullable(true).optional(),
          otherwise: Yup.string()
            .email(t("form.fieldError.invalidEmailAddress"))
            .required(t("form.fieldError.fieldRequired")),
        }),
        [OrderFormikValues.phone]: Yup.string().when("isPlatform", {
          is: true,
          then: Yup.string().nullable(true).optional(),
          otherwise: Yup.string().required(t("form.fieldError.fieldRequired")),
        }),
        [OrderFormikValues.zipCode]: Yup.string().when("isPlatform", {
          is: true,
          then: Yup.string().nullable(true).optional(),
          otherwise: Yup.string().required(t("form.fieldError.fieldRequired")),
        }),
        [OrderFormikValues.address]: Yup.string().when("isPlatform", {
          is: true,
          then: Yup.string().nullable(true).optional(),
          otherwise: Yup.string().required(t("form.fieldError.fieldRequired")),
        }),
        [OrderFormikValues.city]: Yup.string().when("isPlatform", {
          is: true,
          then: Yup.string().nullable(true).optional(),
          otherwise: Yup.string().required(t("form.fieldError.fieldRequired")),
        }),
        [OrderFormikValues.vehicleModel]: Yup.string().when("isPlatform", {
          is: true,
          then: Yup.string().nullable(true).optional(),
          otherwise: Yup.string().required(t("form.fieldError.fieldRequired")),
        }),
        [OrderFormikValues.vehicleColor]: Yup.string().when("isPlatform", {
          is: true,
          then: Yup.string().nullable(true).optional(),
          otherwise: Yup.string().required(t("form.fieldError.fieldRequired")),
        }),
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const { selectedEndDay, selectedStartDay, isPlatform, ...filteredValues } = values;

        const filteredChangedValuesArray = Object.entries(filteredValues).filter(
          ([key, value]) => value !== initialValues[key as keyof typeof initialValues]
        );

        const changedValues = Object.fromEntries(filteredChangedValuesArray) as OrderUpdateValues;

        mutate(
          { changedValues, resetForm },
          {
            onSettled: () => setSubmitting(false),
          }
        );
      }}
    >
      {(props) => (
        <Paper sx={{ height: "100%", display: "flex", flexDirection: "row" }}>
          <Grid container>
            {orderData.platformId && (
              <Grid item xs={12}>
                <PlatformForm readOnly />
              </Grid>
            )}
            <Grid item xs={12} lg={6}>
              <BookingForm
                isRestrictedFieldsEditable={isRestrictedFieldsEditable}
                isEditable={isEditable}
                setSiteStartSlots={setSiteStartSlots}
                setSiteEndSlots={setSiteEndSlots}
              />
            </Grid>
            <Grid item xs={12} lg={6} sx={{ display: "flex", alignItems: "center" }}>
              <CustomerForm
                isRestrictedFieldsEditable={isRestrictedFieldsEditable}
                isEditable={isEditable}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <OperationForm isEditable={isEditable} />
            </Grid>
            {isEditable && (
              <Grid xs={12} item py={4} display="flex" alignItems="center" justifyContent="center">
                <Button
                  onClick={() => props.resetForm()}
                  variant="outlined"
                  sx={{ height: 30, mx: 3 }}
                  disabled={!props.dirty || props.isSubmitting}
                >
                  <Replay />
                </Button>
                <LoadingButton
                  disabled={!props.dirty || !props.isValid}
                  onClick={() => props.handleSubmit()}
                  variant="contained"
                  loading={props.isSubmitting}
                >
                  Save Changes
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </Paper>
      )}
    </Formik>
  );
};

export default EnhancedOrderForm;
